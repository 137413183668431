import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/car-brand",
    component: () => import("../views/BaseData/CarBrand.vue"),
  },
  {
    path: "/dealer-subscription",
    component: () => import("../views/BaseData/DealerSubscription"),
  },
  {
    path: "/user-permission",
    component: () =>
      import("../views/BaseData/user-permission/userPermission.vue"),
  },
  {
    path: "/car-base-model",
    component: () => import("../views/BaseData/CarBaseModel.vue"),
  },
  {
    path: "/car-type",
    component: () => import("../views/BaseData/CarType.vue"),
  },
  {
    path: "/car-model",
    component: () => import("../views/CarModel/CarModel.vue"),
  },
  {
    path: "/ad-current-car-model",
    component: () => import("../views/CarModel/AdCurrentCarModel.vue"),
  },
  {
    path: "/ad-past-car-model",
    component: () => import("../views/CarModel/AdPastCarModel.vue"),
  },
  {
    path: "/pi-current-car-model",
    component: () => import("../views/CarModel/PiCurrentCarModel.vue"),
  },
  {
    path: "/pi-past-car-model",
    component: () => import("../views/CarModel/PiPastCarModel.vue"),
  },
  {
    path: "/platforms",
    component: () => import("../views/BaseData/Platforms.vue"),
  },
  {
    path: "/login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/used-car",
    component: () => import("../views/CarInstance/UsedCar/UsedCar.vue"),
  },
  {
    path: "/pi-new-car",
    component: () =>
      import("../views/CarInstance/PiHandNewCar/PiHandNewCar.vue"),
  },
  {
    path: "/car-model-front",
    component: () => import("../views/CarInstance/CarModelFront.vue"),
  },
  {
    path: "/ad-new-car",
    component: () => import("../views/CarInstance/AdNewCar/AdNewCar.vue"),
  },
  {
    path: "/dealer",
    component: () => import("../views/DealerManagement/Dealer.vue"),
  },
  {
    path: "/account-manager",
    component: () => import("../views/DealerManagement/AccountManager.vue"),
  },
  {
    path: "/cars-for-bidding",
    component: () => import("../views/Bidding/CarsForBidding.vue"),
  },
  {
    path: "/bidding-schedule",
    component: () => import("../views/Bidding/BiddingSchedule.vue"),
  },
  {
    path: "/bidding-events",
    component: () => import("../views/Bidding/BiddingEvents.vue"),
  },
  {
    path: "/incomplete-bidding",
    component: () => import("../views/Bidding/IncompleteBidding.vue"),
  },
  {
    path: "/bidding-logs",
    component: () => import("../views/Bidding/BiddingLogs.vue"),
  },
  {
    path: "/ended-bidding",
    component: () => import("../views/Bidding/EndedBidding.vue"),
  },
  {
    path: "/picture-video",
    component: () => import("../views/Ads/PictureVideo.vue"),
  },
  {
    path: "/petrol-supplier",
    component: () => import("../views/Petrol/PetrolSupplier.vue"),
  },
  {
    path: "/petrol-type",
    component: () => import("../views/Petrol/PetrolType.vue"),
  },
  {
    path: "/petrol-price-info",
    component: () => import("../views/Petrol/PetrolPriceInfo.vue"),
  },
  {
    path: "/coe",
    component: () => import("../views/CoePetrol/Coe.vue"),
  },
  {
    path: "/petrol",
    component: () => import("../views/CoePetrol/Petrol.vue"),
  },
  {
    path: "/live-video-call-schedule",
    component: () => import("../views/LiveVideoCall/Schedule.vue"),
  },
  {
    path: "/boosted-post",
    component: () => import("../views/Advertising/BoostedPosts.vue"),
  },
  {
    path: "/pricing-list",
    component: () => import("../views/Advertising/PricingList.vue"),
  },
  {
    path: "/car-ocr",
    component: () => import("../views/Tools/CarOCR.vue"),
  },
  {
    path: "/car-valuation-log",
    component: () => import("../views/Logs/CarValuationLog.vue"),
  },
  {
    path: "/announcement",
    component: () => import("../views/Announcements/Announcement.vue"),
  },
  {
    path: "/push-notification",
    component: () => import("../views/Announcements/PushNotification.vue"),
  },
  {
    path: "/mass-sms",
    component: () => import("../views/Announcements/MassSMS.vue"),
  },
  {
    path: "/specific-sms",
    component: () => import("../views/Announcements/SpecificSMS.vue"),
  },
  {
    path: "/dealer-analytics-car-value",
    component: () => import("../views/DealerAnalytics/CarValueAnalytics.vue"),
  },
  {
    path: "/dealer-analytics-car-category",
    component: () =>
      import("../views/DealerAnalytics/CarCategoryAnalytics.vue"),
  },
  {
    path: "/dealer-analytics-top-dealer",
    component: () => import("../views/DealerAnalytics/TopDealerAnalytics.vue"),
  },
  {
    path: "/dealer-analytics-car-stats",
    component: () => import("../views/DealerAnalytics/DealerCarAnalytics.vue"),
  },
  {
    path: "/dealer-analytics-account-manager-performance-stats",
    component: () =>
      import("../views/DealerAnalytics/AccountManagerPerformanceAnalytics.vue"),
  },
  {
    path: "/car-not-found",
    component: () => import("../views/CarNotFound/CarNotFound.vue"),
  },
  {
    path: "/contact-us-log",
    component: () => import("../views/Logs/ContactUsLog.vue"),
  },
  {
    path: "/customer-cars",
    component: () =>
      import(
        "../views/UserManagement/CustomerCarListing/CustomerCarListing.vue"
      ),
  },
  {
    path: "/customer-bidding",
    component: () =>
      import("../views/UserManagement/CustomerBidding/CustomerBidding.vue"),
  },
  {
    path: "/customer",
    component: () => import("../views/UserManagement/Customer.vue"),
  },
  {
    path: "/credit-info",
    component: () => import("../views/DealerManagement/CreditInfo.vue"),
  },
  {
    path: "/manage-campaign",
    component: () => import("../views/Campaign/ManageCampaign.vue"),
  },
  {
    path: "/manage-dealer",
    component: () => import("../views/Campaign/ManageDealer.vue"),
  },
  {
    path: "/manage-deals",
    component: () => import("../views/Campaign/ManageDeals.vue"),
  },
  {
    path: "/manage-listing",
    component: () => import("../views/Campaign/ManageListing.vue"),
  },
  {
    path: "/sales-agreement",
    component: () => import("../views/Campaign/SalesAggrement.vue"),
  },
  {
    path: "/sync-dealer",
    component: () => import("../views/DealerManagement/SyncDealer.vue"),
  },
  {
    path: "/ad-dealer",
    component: () => import("../views/DealerManagement/AdDealer.vue"),
  },
  {
    path: "/pi-dealer",
    component: () =>
      import("../views/DealerManagement/PiHandDealer/PiHandDealer.vue"),
  },
  {
    path: "/parent-dealer",
    component: () => import("../views/DealerManagement/parentDealer.vue"),
  },
  {
    path: "/promo-code-management",
    component: () =>
      import("../views/PromoCodeManagement/PromoCodeManagement.vue"),
  },
  {
    path: "/user-subscription",
    component: () =>
      import("../views/PromoCodeManagement/UserSubscription.vue"),
  },
  {
    path: "/partner-register",
    component: () => import("../views/Partner/Register/PartnerRegister.vue"),
  },
  {
    path: "/partner-listing",
    component: () =>
      import("../views/Partner/ParterListing/PartnerListing.vue"),
  },
  {
    path: "/partner-bidding",
    component: () =>
      import("../views/Partner/ParterBidding/PartnerBidding.vue"),
  },
  {
    path: "/tires",
    component: () => import("../views/Tires/Tires.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.fullPath.substr(0, 2) === "/#") {
    const path = to.fullPath.substr(2);
    next(path);
    return;
  }
  next();
});

export default router;
