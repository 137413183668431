<template>
  <v-navigation-drawer absolute permanent app :mini-variant.sync="mini">
    <v-list>
      <v-list-item>
        <v-list-item-title>UCARS</v-list-item-title>
        <v-btn icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list dense expand>
      <v-list-group
        v-for="section in sections"
        :key="section.header"
        :prepend-icon="section.icon"
        no-action
        :value="section.expand"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="section.header"></v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item
          v-for="page in section.pages"
          :key="page.name"
          link
          :to="page.route"
        >
          <v-list-item-content>
            <v-list-item-title v-text="page.name"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data: () => ({
    mini: false,
    sections: [
      {
        header: "Base Data",
        pages: [
          { name: "Car Brand", route: "/car-brand" },
          { name: "Dealer Subscription", route: "/dealer-subscription" },
          { name: "User Permission", route: "/user-permission" },
          // { name: "Car Base Model", route: "/car-base-model" },
          { name: "Car Type", route: "/car-type" },
          { name: "Platforms", route: "/platforms" },
        ],
        icon: "mdi-view-dashboard",
      },
      {
        header: "Car Model",
        pages: [
          { name: "AD Current Car Model", route: "/ad-current-car-model" },
          { name: "AD Past Car Model", route: "/ad-past-car-model" },
          { name: "PI Current Car Model", route: "/pi-current-car-model" },
          { name: "PI Past Car Model", route: "/pi-past-car-model" },
          // { name: "Car Model", route: "/car-model" },
        ],
        icon: "mdi-car",
      },
      {
        header: "Car Instance",
        pages: [
          { name: "AD Car Model Front", route: "/car-model-front" },
          { name: "Used Car", route: "/used-car" },
          // { name: "AD New Car", route: "/ad-new-car" },
          { name: "PI New Car", route: "/pi-new-car" },
        ],
        icon: "mdi-car-sports",
      },
      {
        header: "Tires",
        pages: [{ name: "Tires", route: "/tires" }],
        icon: "mdi-car-sports",
      },
      {
        header: "User Management",
        pages: [
          { name: "Customer", route: "/customer" },
          { name: "Customer Car Listing", route: "/customer-cars" },
          { name: "Customer Bidding", route: "/customer-bidding" },
        ],
        icon: "mdi-account",
      },
      {
        header: "Dealer Management",
        pages: [
          { name: "Shopfront", route: "/parent-dealer" },
          { name: "PI & 2nd hand dealers", route: "/pi-dealer" },
          { name: "AD dealers", route: "/ad-dealer" },
          // { name: "dealers", route: "/dealer" },
          { name: "Account Manager", route: "/account-manager" },
          { name: "Sync Dealer", route: "/sync-dealer" },
          { name: "Credit Info", route: "/credit-info" },
          // { name: "Customer", route: "/customer" },
        ],
        icon: "mdi-account",
      },
      {
        header: "Partner",
        pages: [
          { name: "Register Partner", route: "/partner-register" },
          { name: "Partner Car Listing", route: "/partner-listing" },
          { name: "Partner Bidding", route: "/partner-bidding" },
        ],
        icon: "mdi-account",
      },
      {
        header: "Campaign",
        pages: [
          { name: "Manage Campaign", route: "/manage-campaign" },
          { name: "Manage Dealer", route: "/manage-dealer" },
          { name: "Manage Deals", route: "/manage-deals" },
          { name: "Manage Listing", route: "/manage-listing" },
          { name: "Sales Agreement", route: "/sales-agreement" },
        ],
        icon: "mdi-volume-high",
      },
      {
        header: "Bidding",
        pages: [
          { name: "Cars for Bidding", route: "/cars-for-bidding" },
          // { name: "Bidding Schedule", route: "/bidding-schedule" },
          // { name: "Bidding Events", route: "/bidding-events" },
          // { name: "Incomplete Bidding", route: "/incomplete-bidding" },
          { name: "Bidding Logs", route: "/bidding-logs" },
          // { name: "Ended Bidding", route: "/ended-bidding" },
          {
            name: "Car valuation log",
            icon: "mdi-ocr",
            route: "/car-valuation-log",
          },
        ],
        icon: "mdi-gavel",
      },
      {
        header: "Advertising",
        pages: [
          { name: "Boosted Posts", route: "/boosted-post" },
          { name: "Pricing List", route: "/pricing-list" },
        ],
        icon: "mdi-google-ads",
      },
      {
        header: "COE/Petrol Price",
        pages: [
          { name: "COE", route: "/coe" },
          { name: "Petrol", route: "/petrol" },
        ],
        icon: "mdi-align-vertical-bottom",
      },
      {
        header: "Car Not Found",
        pages: [{ name: "Submissions", route: "/car-not-found" }],
        icon: "mdi-car-off",
      },
      // {
      //   header: "Ads",
      //   pages: [{ name: "Picture / Video", route: "/picture-video" }],
      //   icon: "mdi-bullhorn",
      // },
      // {
      //   header: "Petrol",
      //   pages: [
      //     { name: "Petrol Supplier", route: "/petrol-supplier" },
      //     { name: "Petrol Type", route: "/petrol-type" },
      //     { name: "Petrol Price Info", route: "/petrol-price-info" },
      //   ],
      //   icon: "mdi-gas-station",
      // },
      // {
      //   header: "COE",
      //   pages: [
      //     { name: "COE Info", route: "/coe-info" },
      //     { name: "COE Price", route: "/coe-price" },
      //   ],
      //   icon: "mdi-certificate",
      // },
      // {
      //   header: "Live Video Call",
      //   pages: [{ name: "Schedule", route: "/live-video-call-schedule" }],
      //   icon: "mdi-video-vintage",
      // },
      {
        header: "Tools",
        pages: [
          {
            name: "Car OCR (beta)",
            icon: "mdi-ocr",
            route: "/car-ocr",
          },
        ],
        icon: "mdi-cog",
      },
      {
        header: "Announcements",
        pages: [
          {
            name: "Announcements",
            icon: "mdi-alert",
            route: "/announcement",
          },
          {
            name: "Push Notifications",
            icon: "mdi-alert",
            route: "/push-notification",
          },
          {
            name: "Mass SMS",
            icon: "mdi-alert",
            route: "/mass-SMS",
          },
          {
            name: "Specific SMS",
            icon: "mdi-alert",
            route: "/specific-SMS",
          },
        ],
        icon: "mdi-forum",
      },
      {
        header: "Administrator",
        pages: [
          {
            name: "Login",
            icon: "account",
            route: "/login",
          },
        ],
        icon: "mdi-account",
      },
      {
        header: "Dealer Analytics",
        pages: [
          {
            name: "Car Value Analytics",
            icon: "analytics",
            route: "/dealer-analytics-car-value",
          },
          {
            name: "Car Category Analytics",
            icon: "analytics",
            route: "/dealer-analytics-car-category",
          },
          {
            name: "Top Dealer",
            icon: "analytics",
            route: "/dealer-analytics-top-dealer",
          },
          {
            name: "Dealer Car Analytics",
            icon: "analytics",
            route: "/dealer-analytics-car-stats",
          },
          {
            name: "Account Manager Performance Analytics",
            icon: "analytics",
            route: "/dealer-analytics-account-manager-performance-stats",
          },
        ],
        icon: "mdi-google-analytics",
      },
      {
        header: "Logs",
        pages: [
          {
            name: "Contact Us Log",
            route: "/contact-us-log",
          },
        ],
        icon: "mdi-cog",
      },
      {
        header: "Promo Code Management",
        pages: [
          {
            name: "Promo Codes",
            route: "/promo-code-management",
          },
          {
            name: "User Subscription",
            route: "/user-subscription",
          },
        ],
        icon: "mdi-tag-text-outline",
      },
    ],
  }),
};
</script>
